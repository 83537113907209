<template>
  <v-dialog v-model="dialog" persistent max-width="800px" eager>
    <v-card>
      <v-card-title>
        <span class="headline">{{title}}</span>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="4">
              <app-code-field
                label="Código"
                v-model="form.code"
                :clear-on-auto-code="isInsert"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <app-text-field
                :rules="[rules.required]"
                label="Nome da Categoria"
                v-model="form.name"
              />
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col>
              <v-switch
                class="mt-0"
                v-model="form.status"
                hide-details
                label="Ativo"
                :true-value="1"
                :false-value="0"
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end mr-2 pb-6">
        <v-btn
          @click="dialog = false"
          class="text-capitalize"
          text
          color="secondary"
          >Cancelar</v-btn
        >
        <v-btn @click="handleSave()" class="text-capitalize" color="primary" :disabled="!valid"
          >Salvar</v-btn
        >
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,

      form: {},

      rules: {
        required: (value) => !!value || "Required.",
      },

      baseForm: {
        id: null,
        code: null,
        name: null,
        status: 1,
      },
      title:null,
      valid: null,
    };
  },

  computed: {
    isInsert() {
      return !this.form.id;
    },
  },

  methods: {
    async open(id) {
      this.reset();

      if (id) {
        await this.showCategory(id);
      }

      this.dialog = true;
    },

    reset() {
      this.title = "Nova Categoria";
      this.form = JSON.parse(JSON.stringify(this.baseForm));
      this.$refs.form.resetValidation();
    },

    async showCategory(id) {
      this.title = "Atualizar Categoria";
      this.$store.commit("app/showLoading");
      await this.$http.$get("product/category/" + id).then((response) => {
        this.form = response.category;
        this.$store.commit("app/hideLoading");
      });
    },

    handleSave() {
      this.$refs.form.validate();
      if (this.valid == true) {
        if (this.isInsert) {
          this.store();
        } else {
          this.update();
        }
      }
    },

    store() {
      this.$http.store("product/category", this.form).then((response) => {
        this.processSaved();
      });
    },

    update() {
      this.$http
        .update("product/category", this.form.id, this.form)
        .then((response) => {
          this.processSaved();
        });
    },

    processSaved() {
      this.$emit("store");
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>
<!--template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <app-text-field :rules="nameRules" label="Nome da Categoria" v-model="form.name" />
        </v-card-text>
        <v-card-text>
          <v-checkbox
            :true-value="1"
            :false-value="0"
            v-model="form.status"
            :label="statusLabel[form.status]"
          />
        </v-card-text>
        <v-card-actions
          class="justify-end py-4"
          style="background-color: #f5f5f5"
        >
          <v-btn
            @click="dialog = false"
            text
            class="text-capitalize"
            color="secondary"
            >Cancelar</v-btn
          >
          <v-btn @click="handleSave()" class="text-capitalize" color="primary"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</-template>

<script>
export default {
  components: {},
  data() {
    return {
      nameRules: [(v) => !!v || "O nome da Categoria é obrigatório"],
      statusLabel: {
        1: "Este cadastro está ativo",
        0: "Este cadastro está inativo",
      },
      baseForm: {
        status: 1,
        name: null,
      },
      dialog: false,
      title: null,
      valid: false,
    };
  },
  created() {
    this.reset();
  },
  computed: {
    isInsert() {
      return !this.form.id;
    },
  },
  methods: {
    async open(id) {
      this.reset();
      if (id) {
        await this.showCategory(id);
      }

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
      this.title = "Nova Categoria";
    },
    async showCategory(id) {
      this.title = "Atualizar Categoria";
      this.$store.commit("app/showLoading");
      await this.$http.$get("product/category/" + id).then((response) => {
        this.form = response.category;
        this.$store.commit("app/hideLoading");
      });
    },

    handleSave() {
      this.$refs.form.validate();
      if (this.valid == true) {
        if (this.isInsert) {
          this.store();
        } else {
          this.update();
        }
      }
    },

    store() {
      this.$http.store("product/category", this.form).then((response) => {
        this.processSaved();
      });
    },

    update() {
      this.$http
        .update("product/category", this.form.id, this.form)
        .then((response) => {
          this.processSaved();
        });
    },

    processSaved() {
      this.$emit("store");
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>