<template>
  <div>
    <v-row>
      <v-col cols="4">
        <app-text-search-field @input="handleSearchInput($event)" />
      </v-col>
      <v-col class="text-right d-md-block d-none" cols="8">
        <v-btn
          class="text-capitalize"
          bottom
          color="primary"
          v-if="$acl.can('categoryCreateEdit')"
          @click="handleNewCategory()"
          >Novo</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="categories.data"
          :items-per-page="30"
          hide-default-footer
          @click:row="handleNewCategory($event.id)"
        >
          <template v-slot:[`item.status`]="{ item }">
            <status-chip :value="item.status" />
          </template>
        </v-data-table>
        <v-pagination
          v-model="searchParams.page"
          :length="categories.last_page"
          @input="select($event)"
          :total-visible="9"
        />
      </v-col>
    </v-row>
    <StoreCategoriesForm @store="select()" ref="StoreCategoriesForm" />
  </div>
</template>

<script>
import StoreCategoriesForm from "@/components/categories/forms/StoreCategoriesForm.vue";

export default {
  components: { StoreCategoriesForm },

  data() {
    return {
      singleSelect: false,
      headers: [
        { sortable: false, text: "Código", value: "code" },
        { sortable: false, text: "Nome", value: "name", width: "80%" },
        { sortable: false, text: "Status", value: "status" },
      ],
      loading: false,
      categories: {},
      searchParams: {
        page: 1,
      },
    };
  },
  mounted() {
    this.select();
  },
  methods: {
    select(page = 1) {
      this.loading = true;
      this.searchParams.page = page;
      this.$http
        .index("product/category", this.searchParams)
        .then((response) => {
          this.categories = response.categories;
          this.loading = false;
        });
    },
    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },

    handleNewCategory(id = null) {
      if (this.$acl.can("categoryCreateEdit")) {
        this.$refs.StoreCategoriesForm.open(id);
      }
    },
  },
};
</script>

<style>
</style>